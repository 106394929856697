import React from "react";
import theme from "theme";
import { Theme, Link, Text, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				The Symphony Fund
			</title>
			<meta name={"description"} content={"Put your money where your mouth is."} />
			<meta property={"og:title"} content={"The Symphony Fund"} />
			<meta property={"og:description"} content={"Put your money where your mouth is."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Section padding="60px 0 20px 0" quarkly-title="HeroBlock">
			<Text
				color="--dark"
				lg-font="normal 800 40px/1.2 &quot;Manrope&quot;, sans-serif"
				md-font="normal 800 28px/1.2 &quot;Manrope&quot;, sans-serif"
				margin="0px 0px 0px 0px"
				font="--headline1"
				sm-font="--headline4"
			>
				The Symphony Fund
			</Text>
			<Image
				max-height="600px"
				object-position="0% 0%"
				lg-max-height="300px"
				md-max-height="200px"
				sm-max-height="200px"
				src="https://uploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19:50:22.546Z"
				width="100%"
				object-fit="cover"
				margin="32px 0px 0px 0px"
				srcSet="https://smartuploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19%3A50%3A22.546Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19%3A50%3A22.546Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19%3A50%3A22.546Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19%3A50%3A22.546Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19%3A50%3A22.546Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19%3A50%3A22.546Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65ad728e8d4a0c00202a1ed6/images/1-FinancialOffices-home-1-symphonyNacht.jpg?v=2024-01-21T19%3A50%3A22.546Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});